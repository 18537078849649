require('knacss/sass/knacss.scss');

import 'slick-slider/slick/slick.css';
import '../sass/app.scss';

import $ from 'jquery';
global.$ = global.jQuery = $;

import 'slick-slider';

require('./front/components/all');
require('./front/form/all');
require('./front/pages/all');
require('../../vendor/maier/front-common-bundle/Resources/assets/js/website/maier_main/base');

// Temporary from 20th november (to remove 10th december 2024: useless)

let currentDate = new Date();
let minimumCampainDate = new Date('11/20/2024'); // 20th November
let maximumCampainDate = new Date('12/10/2024'); // 10th December

if (-1 !== window.location.href.search('breitling') && minimumCampainDate.getTime() < currentDate.getTime() && currentDate.getTime() < maximumCampainDate.getTime()) {
    document.addEventListener('DOMContentLoaded', function () {
        document.querySelectorAll('.maier-section-shops').forEach(function (section) {
            section.style.display = 'none';
        });

        document.querySelectorAll('.rolex-retailer-clock-container').forEach(function (container) {
            container.style.display = 'none';
        });
    })
}