document.addEventListener('DOMContentLoaded', function () {
    function initProductQuantityInputEvents() {
        document.querySelectorAll('.maier-customer-cart-products-list-item-content-information-actions-quantity-input').forEach(function (input) {
            input.addEventListener('focus', function () {
                input.setAttribute('data-quantity-current', input.value);

                input.addEventListener('blur', function () {
                    let productQuantityDifference = input.value - input.getAttribute('data-quantity-current');

                    if (productQuantityDifference === 0) {
                        return;
                    }

                    let form = productQuantityDifference > 0 ? input.parentNode.querySelector('#shopping-cart-add-product-form') : input.parentNode.querySelector('#shopping-cart-remove-product-form');

                    form.querySelector('[name="quantity"]').value = Math.abs(productQuantityDifference);
                    form.querySelector('button[type="submit"]').click();
                }, { once: true })
            })
        });
    }

    if (null !== document.querySelector('.maier-customer-cart')) {
        new AjaxForm({
            selector: '.ajax-form',
            targetElements: {
                '.actions > .shopping-cart': '.actions > .shopping-cart',
                '.maier-customer-container': '.maier-customer-container'
            },
            callback: function() {
                initProductQuantityInputEvents();
            },
            onError: function (response) {
                if (!response.ok) {
                    response.json().then(function (json) {
                        Toast.error(json.message, 5000);
                    });

                    document.querySelectorAll('.maier-customer-cart-products-list-item-content-information-actions-quantity-input').forEach(function (input) {
                        if(input.hasAttribute('data-quantity-current')) {
                            input.value = input.getAttribute('data-quantity-current');
                        }
                    });
                }
            }
        });

        initProductQuantityInputEvents();
    }
})
