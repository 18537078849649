import EnhancedEcommerceProduct from "./EnhancedEcommerceProduct";

export default class EnhancedEcommerce {
  constructor() {
    window.dataLayer = window.dataLayer || [];
    this.products = {};
    this.promotions = {};
  }

  viewItemList(enhancedEcommerceProducts) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
        return;
    }

    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        items: Object.values(this.products)
      }
    });
  }

  selectItem(enhancedEcommerceProduct) {
    this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);

    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [this.products[enhancedEcommerceProduct.getItemId()]]
      }
    });
  }

  viewItem(enhancedEcommerceProduct) {
    this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);

    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [this.products[enhancedEcommerceProduct.getItemId()]]
      }
    });
  }

  viewCart(enhancedEcommerceProducts) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
      return;
    }

    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        items: Object.values(this.products)
      }
    });
  }

  addToCart(enhancedEcommerceProduct) {
    this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);

    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [this.products[enhancedEcommerceProduct.getItemId()]]
      }
    });
  }

  removeFromCart(enhancedEcommerceProduct) {
    this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);

    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [this.products[enhancedEcommerceProduct.getItemId()]]
      }
    });
  }

  viewPromotions(enhancedEcommercePromotions) {
    enhancedEcommercePromotions.forEach((enhancedEcommercePromotion) => {
      this.addEnhancedEcommercePromotion(enhancedEcommercePromotion);
    })

    if (Object.keys(this.promotions).length === 0 && this.promotions.constructor === Object) {
      return;
    }

    window.dataLayer.push({
      event: 'view_promotion',
      ecommerce: {
        items: Object.values(this.promotions)
      }
    });
  }

  selectPromotion(enhancedEcommercePromotion) {
    this.addEnhancedEcommercePromotion(enhancedEcommercePromotion);

    window.dataLayer.push({
      event: 'select_promotion',
      ecommerce: {
        items: [this.promotions[enhancedEcommercePromotion.getItemId()]]
      }
    });
  }

  beginCheckout(enhancedEcommerceProducts, checkoutOptions) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
        return;
    }

    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: Object.values(this.products)
      }
    });
  }

  addShippingInfo(enhancedEcommerceProducts, checkoutOptions) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
        return;
    }

    window.dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: {
        shipping_tier: checkoutOptions.shipping_tier,
        city: checkoutOptions.city,
        zipCode: checkoutOptions.zipCode,
        country: checkoutOptions.country,
        items: Object.values(this.products)
      }
    });
  }

  addPaymentInfo(enhancedEcommerceProducts, checkoutOptions) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
        return;
    }

    window.dataLayer.push({
      event: 'add_payment_info',
      ecommerce: {
        payment_type: checkoutOptions.payment_type,
        items: Object.values(this.products)
      }
    });
  }

  purchase(enhancedEcommerceProducts, checkoutOptions) {
    enhancedEcommerceProducts.forEach((enhancedEcommerceProduct) => {
      this.addEnhancedEcommerceProduct(enhancedEcommerceProduct);
    })

    if (Object.keys(this.products).length === 0 && this.products.constructor === Object) {
        return;
    }

    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: checkoutOptions.transaction_id,
        currency: checkoutOptions.currency,
        value: parseFloat(parseFloat(checkoutOptions.value).toFixed(2)),
        tax: parseFloat(parseFloat(checkoutOptions.tax).toFixed(2)),
        shipping: parseFloat(parseFloat(checkoutOptions.shipping).toFixed(2)),
        items: Object.values(this.products)
      },
      new_customer: checkoutOptions.new_customer,
      user_data: {
        user_id: checkoutOptions.user_id,
        email_address: checkoutOptions.email_address,
        phone_number: checkoutOptions.phone_number,
        address: {
          first_name: checkoutOptions.first_name,
          last_name: checkoutOptions.last_name,
          city: checkoutOptions.city,
          postal_code: checkoutOptions.postal_code,
          country: checkoutOptions.country
        }
      }
    });
  }

  addEnhancedEcommerceProduct(enhancedEcommerceProduct) {
    if (!EnhancedEcommerceProduct.prototype.isPrototypeOf(enhancedEcommerceProduct)) {
      throw new Error(
        "The given object is not a type of EnhancedEcommerceProduct"
      );
    }

    if (!this.products.hasOwnProperty(enhancedEcommerceProduct.getItemId())) {
      this.products[
        enhancedEcommerceProduct.getItemId()
      ] = enhancedEcommerceProduct;
    }
  }

  addEnhancedEcommercePromotion(enhancedEcommercePromotion) {
    if (!EnhancedEcommercePromotion.prototype.isPrototypeOf(enhancedEcommercePromotion)) {
      throw new Error(
        "The given object is not a type of EnhancedEcommercePromotion"
      );
    }

    if (!this.promotions.hasOwnProperty(enhancedEcommercePromotion.getPromotionId())) {
      this.promotions[
        enhancedEcommercePromotion.getPromotionId()
      ] = enhancedEcommercePromotion;
    }
  }

  clear () {
    this.clearEnhancedEcommerceProducts();
    this.clearEnhancedEcommercePromotions();
  }

  clearEnhancedEcommerceProducts() {
    this.products = {};
  }

  clearEnhancedEcommercePromotions() {
    this.promotions = {};
  }
}
